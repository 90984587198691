<template>
  <div>
    <div
      v-if="!hidePricing && product.eclipse_data"
      class="pricing mt-2 animate__animated animate__fadeIn animate__faster"> 
      <strong v-if="productPricing.price > 0">
        <!-- Price -->
        <span v-if="!productPricing.bestPrice">
          <template v-if="!virtualShow">
            <!-- Standard Price & UOM -->
            <span
              :class="colorClass"
              class="me-1">
              {{numeral(productPricing.price).format('$0,0.00')}}
              <small v-if="!multiUom || hideSelector">
                {{productPricing.uom}}
              </small>
            </span>
            <!-- Slashout Price -->
            <s
              v-if="(productPricing.promo || productPricing.closeout) && productPricing.originalPrice > 0 && (productPricing.originalPrice > productPricing.price)"
              class="text-muted"
              style="font-size: 14px;">
              {{numeral(this.activeUom ? productPricing.calcOriginalPrice : productPricing.originalPrice).format('$0,0.00')}}
              <small
                v-if="!multiUom || hideSelector"
                class="ms-1">
                {{productPricing.uom}}
              </small>
              <SqPrice
                :product="product"
                originalPrice="true"
                :pricing="productPricing"/>
            </s>
            <!-- UOM Selector EJW Only Option -->
            <UomSelector
              v-if="multiUom && !hideSelector"
              @setActiveUom="setActiveUom"
              :product="product"/>
            <!-- SQFT Pricing if required by product-->
            <template v-if="sqCalc">
              <SqPrice            
                :product="product"
                :pricing="productPricing"
                style="font-size: 14px;"/>
              <br>
            </template>
          </template>
          <!-- Virtual Show Pricing -->
          <template v-if="virtualShow">
            <span class="text-success">
              {{numeral(productPricing.price).format('$0,0.00')}} {{productPricing.uom}}
            </span>
            <s
              v-if="productPricing.originalPrice > productPricing.price"
              class="text-muted ms-2"
              style="font-size:14px;">
              {{numeral(productPricing.originalPrice).format('$0,0.00')}} {{productPricing.uom}}
            </s>
          </template>
        </span>
        <!-- Your Price -->
        <span
          v-else
          class="text-info">
          <span class="badge bg-info">Your Price</span>
            {{numeral(productPricing.eclipsePrice).format('$0,0.00')}}
        </span>
        <!-- Promo End Date -->
        <div v-if="productPricing.promo && promoEndDate">
          <small>
            Promo Ends: {{ moment(productPricing.promoEndDate).format('MM/DD/YYYY') }}
          </small>
        </div>
      </strong>
      <!-- No Price Handler -->
      <strong v-else>
        Call for Pricing
      </strong>
      <!-- Only internal staff allowed to see comm cost -->
      <div v-if="showCost && internalRoles.includes(user.role)" class="text-info">
        Cost:
        <span v-if="cost">{{numeral(this.product.eclipse_data.comm_cost).format('$0,0.00')}}</span>
        <span v-else>No Data</span>
      </div>
    </div>
    <div
      v-if="!hidePricing && !product.eclipse_data"
      class="mt-2">
      <Spinner style="height: 1rem; width: 1rem; border: 2px solid gray; border-right-color: transparent;"/>
    </div>
  </div>
</template>

<script>
  import { productPricing } from '../../../../../shared/ProductPricing'
  import { internalRoles } from '../../../../../shared/roles'
  import SqPrice from './SqPrice.vue'
  import UomSelector from './UomSelector.vue'
  import Spinner from '../../../../global/Spinner.vue'

  export default {
    inject:['env'],
    props: {
      product: Object,
      virtualShow: {
        type: Boolean,
        default: false
      },
      promoEndDate: {
        type: Boolean,
        default: true
      },
      hideSelector: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return{
        internalRoles,
        activeUom: null
      }
    },
    components:{
      SqPrice,
      UomSelector,
      Spinner
    },
    methods:{
      setActiveUom(uom){this.activeUom = uom}
    },
    watch:{
      activeUom(newVal){if(newVal) this.$emit('activeUom', this.productPricing)}
    },
    computed:{
      company(){return this.$store.getters.sessionCompany},
      productPricing(){
        if(this.product.eclipse_data){
          let pricing = productPricing(this.product, this.virtualShow, this.company)
          if(this.activeUom){
            pricing.price = this.activeUom.price
            pricing.uom = this.activeUom.uom
            pricing.calcOriginalPrice = pricing.originalPrice * this.activeUom.quantity
          }
          return pricing
        }
      },
      colorClass(){
        if(this.productPricing.promo){
          return 'text-success'
        }else if(this.productPricing.closeout){
          return 'text-danger'
        }
      },
      hidePricing(){return this.$store.getters.hidePricing},
      showCost(){return this.$store.getters.showCost},
      user(){return this.$store.getters.user},
      cost(){
        if(this.product.eclipse_data.comm_cost){
          let comm_cost = this.product.eclipse_data.comm_cost
          if(comm_cost == 0 || comm_cost == 99999){
            return false
          }
          return comm_cost
        }
        return null
      },
      sqCalc(){return ['sf', 'sy'].includes(this.productPricing.priceUom)},
      multiUom(){return _.filter(this.product.eclipse_data.uom_pricing, {visible: true}).length > 1 && this.env.includes('EJW')}
    }
  }
</script>

<style scoped>
  .pricing{
    font-size:16px;
    line-height: 20px;
    width: 100%;
  }
</style>